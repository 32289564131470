.ui-jqgrid-toppager {
	background: lightgreen !important;
	color: black !important;
}

span.pagerClass {
    width: 20px !important;
	margin-left: 10px !important;
}

.ui-jqgrid .ui-jqgrid-htable th div {
overflow:hidden;
position:relative;
height:17px;
color: white;
font-weight: bold;
}

.ui-jqgrid {
	-webkit-transform: translate3d(0,0,0);
}

.ui-th-ltr,.ui-jqgrid .ui-jqgrid-htable th.ui-th-ltr {
border-left:0 none;
background: #999;
color: white;
font-weight: bold;
}

.ui-th-rtl,.ui-jqgrid .ui-jqgrid-htable th.ui-th-rtl {
border-right:0 none;
background: #999;
color: white;
font-weight: bold;
}

.ui-jqgrid .ui-jqgrid-titlebar {
position:relative;
border-left:0 none;
border-right:0 none;
border-top:0 none;
padding:.3em .2em .2em .3em;
}

.ui-jqgrid-titlebar-earned {
position:relative;
border-left:0 none;
border-right:0 none;
border-top:0 none;
padding:.3em .2em .2em .3em;
background: #0066ff;
color: white;
font-weight: bold;
}

.ui-jqgrid-titlebar-errors {
position:relative;
border-left:0 none;
border-right:0 none;
border-top:0 none;
padding:.3em .2em .2em .3em;
background: #ff66ff;
color: white;
font-weight: bold;
}

.ui-jqgrid-titlebar-faults {
position:relative;
border-left:0 none;
border-right:0 none;
border-top:0 none;
padding:.3em .2em .2em .3em;
background: #9933ff;
color: white;
font-weight: bold;
}

.ui-jqgrid .ui-jqgrid-title {
float:left;
margin:.1em 0 .2em;
}

th.ui-th-column div{
white-space:normal !important;
height:auto !important;
padding:2px;
}

.ui-jqgrid tr.jqgrow td,.ui-jqgrid tr.jqgroup td {
word-wrap: break-word; /* IE 5.5+ and CSS3 */
white-space: pre-wrap; /* CSS3 */
white-space: -moz-pre-wrap;  /*Mozilla, since 1999 */
white-space: -pre-wrap; /* Opera 4-6 */
white-space: -o-pre-wrap; /* Opera 7 */
overflow: hidden;
height: auto;
vertical-align: middle;
padding-top: 3px;
padding-bottom: 3px
}

/*.ui-jqgrid .ui-jqgrid-title { 
	font-size: 1.2em;
}*/